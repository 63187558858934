/* @flow */

module.exports = {
  "LOCALE": {
    "ISO": {
      "639-1": "en",
      "3166-1": "GB",
      "IETF": "en-GB",
    },
    "CURRENCY": "EUR",
    "CURRENCY_SHORT": "€",
    "POINTS": "points",
    "BASIC_POINTS": "Basic points",
    "EXTRA_POINTS": "Extra points",
  },
  "META": {
    "GOOGLE_SITE_OWNER": "JE5RJH9mE7LLsEhBFrF7e0T3poF_BesW7P3wibE8XwA",
    "HELPDESK_LINK": "https://helpdesk.crossroads.se/?s=6226d0b48e446ffaf4a92e6a0c1d653f",
  },
  "LOGO": {
    "ALT": "SAS",
    "TITLE": "Go to home page",
  },
  "COOKIE_CONSENT": {
    "ALL": "Allow all",
    "SELECTED": "Allow selection",
    "NECESSARY": "Allow necessary",
  },
  "COOKIE_OPTIONS": {
    "NECESSARY": "Necessary",
    "STATISTICAL": "Statistical",
    "MARKETING": "Marketing",
  },
  "CURRENCY_SHORT": {
    "NOK": "kr",
    "DKK": "kr",
    "SEK": "kr",
    "EUR": "€",
  },
  "APP": {
    "COUNTRY_MODAL": "You have logged in to an account registered in %country. Would you like to switch to a store that delivers to %country?",
    "BREXIT_NOTICE": {
      "TEXT": "Due to Brexit, we are currently unable to deliver to the United Kingdom. Unfortunately, we are unable to extend the validity of points that expire due to this.",
      "LINK": "Read more in our FAQ.",
      "URL": "/customer-service",
    },
  },
  "HOMEVIEW": {
    "POPULAR_CATEGORIES": "Popular categories",
    "SEE_MORE_POPULAR_CATEGORIES": "View more categories",
    "POPULAR_PRODUCTS": "Popular products",
    "SEE_MORE_POPULAR_PRODUCTS": "View more popular products",
    "RECENTLY_VIEWED_PRODUCTS": "Recently viewed products",
  },
  "ALL_PRODUCTS": {
    "TITLE": "All products",
    "TITLE_PREFIX_LOADING": "Loading all",
  },
  "PRODUCTLIST_HERO": {
    "PRODUCTS_FROM": "Products from",
    "TITLE_PREFIX_EMPTY": "No products available",
    "TITLE_PREFIX_SINGULAR": "Showing 1 product in <span>%title</span>",
    "TITLE_PREFIX_PLURAL": "Showing %itemsCount products in <span>%title</span>",
    "IN_PRICERANGE": " in the price range <span>%points points</span>",
    "POINTS": "points",
  },
  "ADVENT_CALENDAR": {
    "TEXT": "Advent calendar",
    "LINK": "/advent-calendar",
    // "LINK": "/advent-calendar?testDate=2020-12-1&password=foo",
    "DOOR_TITLE": "Door %day",
    "VIEW_ALL_PRODUCTS": "View all products in today's door",
    "BACK": "Back to advent calendar",
    "SAVE_PERCENTAGE": "Save up to %percentage%",
    "CATEGORY_URL": "advent-calendar",
    "OPEN_DOOR": "Open door",
    "OPEN_DOOR_ALT": "Open door",
    "CLOSED_DOOR_ALT": "Closed door",
    "CALENDAR_CLOSED": "The Advent Calendar has closed.",
    "TOTAL_COUNT_EMPTY": "Couldn't find any products.",
    "TOTAL_COUNT_SINGULAR": "Viewing 1 product",
    "TOTAL_COUNT_PLURAL": "Viewing %totalCount products",
    "LANDING": {
      "HEADING": "EUROBONUS SHOP ADVENT CALENDAR",
      "TEXT": "Exciting offers on christmas presents every day until Christmas Eve. Starting December 1st.",
    },
    "FORM": {
      "SUBSCRIBE_NOW": "Don't miss the first door – subscribe now!",
      "EMAIL": "Email",
      "SUBMIT": "Subscribe",
    },
  },
  "ACCOUNT": {
    "MY_ACCOUNT": "My account",
    "LOGOUT": "Log out",
    "ACCOUNT_INFORMATION": "Account information",
    "OWNER": "Owner",
    "MEMBERSHIP": "Membership",
    "MEMBERSHIP_LEVEL": "Membership level",
    "MEMBER_NUMBER": "EuroBonus number",
    "LAST_REGISTERED_POINTS": "Last registered points",
    "POINTS_READ_MORE": "Read more",
    "POINTS_READ_MORE_LINK": "https://www.flysas.com/",
    "SHIPPING_ADDRESS": "Shipping Address",
    "ELAPSED_POINTS": "Points that are about to expire",
    "ELAPSING_POINTS_INFO": "You have %points points that will expire %date (%dateRelative)",
    "EDIT_INFO_TEXT": "You can edit your account details at flysas.com",
    "EDIT_INFO": "Edit my account details",
    "WISHLIST": "Wishlist",
    "EMAIL": "Email",
    "BALANCE": "EuroBonus balance",
    "TOTAL_POINTS": "Total",
    "DATE": "Date",
    "SEE_ALL": "View all",
    "BUY_AND_TRANSFER_POINTS": "Buy & Transfer points",
    "EDIT": "Edit",
    "EDIT_URL": "https://www.flysas.com/profile/#/profile?userAction=Settings",
    "READ_MORE": "Read more",
    "TIER": {
      "B": "Member",
      "S": "Silver",
      "G": "Gold",
      "D": "Diamond",
      "P": "Pandion",
    },
  },
  "COUNTRY": {
    "HEADING": "Welcome traveler!",
    "DELIVER_TO": "Deliver to",
  },
  "GIFT_CARDS": {
    "HEADING": "Looking for gift cards?",
    "DESCRIPTION": "Currently we do not offer Gift Cards in your region. Please select one of our Nordic regions to view and shop our Gift Cards.",
  },
  "ORDER_HISTORY": {
    "PAGINATION": "Page %current / %total",
  },
  "PURCHASE_POINTS": {
    "CUSTOMER_NOT_ELIGABLE": "You don't have access to buy and transfer points yet. In order to purchase, transfer or receive Extra points you need to have been a member for a minimum of 1 year, and have at least one activity registered to your EuroBonus account. In addition to that, you need at least 1,000 points available in your account. If you need more points in order to reach the next membership level or remain at your current level, you can purchase the Basic points needed. This is especially useful if you are missing a limited amount of points.",
    "STEP_NAMES": {
      "find-recipient": "Find recipient",
      "point-select": "Choose amount",
      "payment-split": "Payment distribution",
      "payment": "Summary",
    },
    "TITLE": "Buy & Transfer points",
    "DESCRIPTION": "Transfer Extra points, or buy Extra points or the missing Basic points you need.",
    "BALANCE": "Your point balance is %balance",
    "BASIC_POINTS_EARNED": "You've earned a total of %earned Basic Points during this qualification period.",
    "BUY_FOR_FRIEND": "Buy for a friend",
    "TRANSFER_TO_FRIEND": "Transfer to a friend",
    "BUY_FOR_SELF": "Buy for yourself",
    "EDIT": "Edit",
    "SAVE_AND_PROCEED": "Save & proceed",
    "CHOOSE": "Select",
    "CALCULATING": "…",
    "HOW_MANY_POINTS_PURCHASE": "How many points would you like to buy?",
    "HOW_MANY_POINTS_TRANSFER": "How many Extra Points would you like to transfer?",
    "YOU_WILL_RECEIVE": "You will transfer",
    "SELECT_POINTS_TOOLTIP_TITLE": "Choose amount",
    "SELECT_POINTS_TOOLTIP_DESCRIPTION": "Use the slider to choose the amount of points to buy or transfer.",
    "PAYMENT_TOOLTIP_TITLE": "How would you like to pay the transaction fee?",
    "PAYMENT_TOOLTIP_DESCRIPTION": "Move the slider to adjust the payment distribution of the transaction fee. If you only want to use points, drag the slider all the way to the right and if you only want to pay with money, drag it all the way to the left.",
    "WHO_IS_RECIPIENT": "Who is the recipient?",
    "RECIPIENT": "Recipient",
    "CONFIRMATION": "Confirmation",
    "CONFIRMATION_SUBTITLE": "Split the payment between Extra Points and card payment.",
    "FAQ": {
      "TITLE": "FAQ",
      "1": {
        "Q": "The points I wish to transfer to someone else's account are about to expire. For how long will they be valid in the new account?",
        "A": "Transferred EuroBonus Extra points are valid for 5 years from the date of transfer. Please note that returning points to your account will cause previously expired points to expire, meaning you cannot recover points that have expired since the transaction was made.",
      },
      "2": {
        "Q": "Who can purchase EuroBonus Extra points?",
        "A": "In order to purchase, transfer or receive Extra points you need to have been a member for a minimum of 1 year, and have at least one activity registered to your EuroBonus account. In addition to that, you need at least 1,000 points available in your account.",
      },
      "3": {
        "Q": "Can I reach a higher membership level by purchasing Extra points or having them transferred to my account?",
        "A": "No, Extra points do not qualify you for a higher membership level. In order to reach the next membership level you must obtain Basic points. Please note that purchasing Basic points is only possible after earning the majority of your Basic points through flying.",
      },
      "4": {
        "Q": "How many points can one member receive from another member each year?",
        "A": "You can purchase and receive up to a maximum of 100,000 EuroBonus Extra points every year. Please note that purchased and received points are counted together, and not separately.",
      },
      "VIEW_MORE": "View more frequently asked questions",
    },
    "FIND_RECIPIENT": {
      "EB": "EuroBonus number",
      "FIND_TRAVELER": "Find traveler",
      "LAST_NAME": "Last name",
      "SUCCESS": "This traveler can recieve an additional %points this year.",
    },
    "PAYMENT_BOX": {
      "TITLE": "How do you want to pay the transfer fee?",
      "PAY_WITH_POINTS": "Pay with points (+%points points)",
      "PAY_WITH_CASH": "Pay with card (+%cash SEK)",
      "SUMMARY": "Summary",
      "GIFTED_POINTS": "Amount of points",
      "TRANSACTION_FEE": "Transfer fee",
      "TO_PAY": "To pay",
      "TRANSFER_POINTS": "Transfer points",
    },
    "SUCCESS": {
      "TITLE": "Points transferred",
      "ORDER_NUMBER": "Order id",
      "ORDER_OVERVIEW": "Overview",
      "CONFIRMATION": "Confirmation",
      "CONFIRMATION_INFO": "You have completed a transfer of EuroBonus points to %member. You will receive an email confirmation of the transaction shortly. Don't hesitate to contact customer service, should you have any questions regarding the transaction.",
      "INFORMATION": "Information",
      "INFORMATION_INFO": "Please allow up to 24h before the EuroBonus points are available in the recipient's account.",
      "CONTACT_US": "Contact us",
      "CONTACT_US_INFO": "Got a question regarding this transaction? Contact our customer service team.",
      "CUSTOMER_SERVICE": "Customer service",
      "TRAVELER": "Member",
      "EUROBONUS_NUMBER": "EuroBonus number",
      "LAST_NAME": "Last name",
      "ORDER_CONTENTS": "Order contents",
      "PRODUCT": "Product",
      "PAYMENT": "Payment",
      "CASH": "Card payment",
      "POINTS": "Points",
    },
    "STEPS": {
      "TRAVELER": "Recipient",
      "PURCHASE_POINTS": "Amount",
      "CONFIRMATION": "Confirmation",
      "SUMMARY": "Summary",
      "PAYMENT": "Payment",
    },
    "TIERS": {
      "EARNED_CURRENT": "You have earned %points during your current qualification period.",
      "EARNED_PREVIOUS": "You earned a total of %points during your previous qualification period.",
      "EXTEND": "Purchase another %points to extend your",
      "UPGRADE": "Purchase another %points to upgrade to",
      "LEVEL": "membership.",
    },
    "BASIC_POINTS": {
      "HEADER": "Buy basic points",
      "INFO": "Your account does not meet the requirements to buy basic points.",
      "LINK": "For more information, click here.",
    },
  },
  "SORT": {
    "OFFCANVAS": {
      "TITLE": "Sort",
      "SHOW_RESULT": "Show result",
      "CLOSE_MENU": "Close menu",
    },
  },
  "FILTER": {
    "FILTERBAR": {
      "ALL_FILTERS": "All filters",
      "FILTER_BY_POINTS": "Filter by points",
      "FILTER_BY_PRICE": "Filter by price",
      "PRICE_RANGE": "%min - %max points",
      "SORT": "Sort",
      "SORT_DEFAULT": "Best match",
      "SORT_NAME_DOWN": "Product name: Z-A",
      "SORT_NAME_UP": "Product name: A-Z",
      "SORT_PRICE_DOWN": "Most expensive",
      "SORT_PRICE_UP": "Cheapest",
      "SORT_POSITION_DOWN": "Most popular",
      "SORT_POPULAR_DOWN": "Most popular",
      "SORT_VALUE_DOWN": "Value: Low",
      "SORT_VALUE_UP": "Value: High",
      "SORT_RELEASE_DATE_DOWN": "Release Date: Latest",
      "SORT_RELEASE_DATE_UP": "Release Date: Oldest",
      "SORT_LATEST_DOWN": "Latest",
      "SORT_MANUFACTURER_DOWN": "Brand: A-Z",
      "SORT_MANUFACTURER_UP": "Brand: Z-A",
      "SORT_CREATED_AT_DOWN": "Last added: Latest",
      "SORT_CREATED_AT_UP": "Last added: Oldest",
    },
    "OFFCANVAS": {
      "TITLE": "Filter",
      "SHOW_RESULT_TOTAL_SINGLE": "%total Product",
      "SHOW_RESULT_TOTAL_MULTIPLE": "%total Products",
      "SHOW_RESULT": "Show result",
      "CLOSE_MENU": "Close menu",
    },
    "ACTIVE_FILTERS": {
      "CLEAR_ALL": "Clear filter",
      "NO_ACTIVE_FILTERS": "You have no active filters",
      "PRICE_RANGE": "Price (%range)",
      "TOTAL_COUNT": "Viewing %count products",
    },
    "FILTERMENU": {
      "FILTER_BY_PRICE": "Filter by points",
      "FILTER_BY_POINTS": "Filter by price",
      "RESET_PRICE": "Clear",
      "PRICE_FROM": "From",
      "PRICE_TO": "To",
      "BALANCE_FILTER": "Filter by current balance (%value points)",
      "WITHIN_BALANCE": "Products within points balance",
    },
  },
  "WISHLIST": {
    "TITLE": "Wishlist",
    "REMOVE_FROM_WISHLIST": "Remove from wishlist",
    "EMPTY": "You have no items in your wishlist.",
    "EXPLORE_ASSORTMENT": "Explore assortment",
  },
  "ADDRESS": {
    "FIRSTNAME": "First name",
    "LASTNAME": "Last name",
    "STREET_1": "Street address 1",
    "STREET_2": "Street address 2",
    "POSTCODE": "Postal code",
    "CITY": "City",
    "COUNTRY": "Country",
    "EMAIL": "E-mail",
    "TELEPHONE": "Phone number",
    "EDIT": "Edit",
    "NOT_YOUR_COUNTRY": "Not your country?",
  },
  "OR": "or",
  "CART": {
    "EMPTY": "Your cart is empty",
    "CTA": "Go to checkout",
    "CANT_AFFORD_TITLE": "Too low balance",
    "CANT_AFFORD_INFO": "You don't have enough points to place this order.",
    "OF": "of",
    "ALT": "Edit cart contents",
    "EDIT": "Edit cart contents",
    "TO_PAY": "Total amount to pay",
    "INCREMENT": "Increase quantity",
    "DECREMENT": "Decrease quantity",
    "QUANTITY": "Quantity",
    "CONTINUE_SHOPPING": "continue shopping",
    "SHIPPING": "Shipping",
    "SUBTOTAL": "Subtotal",
    "GRANDTOTAL": "Grand total",
    "YOUR_BALANCE": "Current point balance",
    "SUMMARY": "Summary",
    "TO_CHECKOUT": "Go to checkout",
    "TO_CHECKOUT_CANT_AFFORD": "You don´t have enough points to complete this purchase.",
    "REMOVE_PRODUCT": "Remove item",
    "REMOVE_APPROVE": "Yes, remove this item",
    "CONFIRM_TEXT": "Are you sure you'd like to remove '%itemName' from  your cart?",
    "YOULL_EARN": "You will earn %points",
    "MINIMIZE_SUMMARY": "Minimize summary",
    "VIEW_DETAILS": "View details",
    "PAY_ONLY_WITH_PTS": "Points only",
    "ACCRUAL_TOOLTIP_TITLE": "Earn points",
    "ACCRUAL_TOOLTIP_DESCRIPTION": "You earn Eurobonus-points when you buy some products. Points are credited to your account 1 month after the date of purchase.",
  },
  "CONFIRM_DIALOG": {
    "CANCEL": "Cancel",
  },
  "CHECKOUT": {
    "NEED_HELP": "Need help? We're here for you!",
    "CONTACT_CUSTOMER_SERVICE": "Contact customer service",
    "TO_PAYMENT": "Go to payment",
    "CHOOSE_PAYMENT_METHOD": "Proceed to payment",
    "POINTS_TITLE": "Pay using points",
    "POINTS_BALANCE": "You currently have %points. What amount of points would you like to spend?",
    "AMOUNT_TO_PAY": "Amount to be withdrawn",
    "TOOLTIP_TITLE": "Pay using points",
    "TOOLTIP_DESCRIPTION": "SAS EuroBonus Shop accepts payment using points, a credit card or a combination of both. You can choose the amount of points you would like to redeem by adjusting the slider. Any remaining amount will be charged to your credit or debit card. Adjust the slider all the way to the right to pay the order entirely using points or use all your remaining points (whichever is the lesser), or all the way to the left to pay the order entirely using a credit or debit card.",
    "POINTS": "Points",
    "CASH": "Cash",
    "PAY_WITH_CARD": "Pay using card",
    "CARD_PAYMENT": "Pay using card",
    "EDIT": "Change",
    "PAYMENT_AND_DELIVERY": "Payment & Delivery",
    "DISTRIBUTION_POINTS": "EuroBonus-points",
    "DISTRIBUTION_CASH": "Supplementary card payment",
    "SENT_TO": "To be sent to",
    "CONFIRM_ORDER": "Confirm order",
    "CONTINUE_SHOPPING": "Continue shopping",
    "PAYMENT_TITLE": "How would you like to pay?",
    "I_ACCEPT_THE": "I accept",
    "TERMS": "the terms for this purchase",
    "ACCORDION_HEADER_CART": "Cart",

    "ADDRESS": {
      "ADDRESS": "Address",
      "SHIPPING": "Delivery address",
      "BILLING": "Address on receipt",
      "SHIP_TO_SAME_ADDRESS": "Same address on reciept",
      "EMPTY_ADDRESS": "The address is not complete, please update",
    },
    "STEPS": {
      "CART": {
        "SHORT": "Cart",
        "LONG": "Cart",
      },
      "SHIPPING": {
        "SHORT": "Delivery",
        "LONG": "Check your address",
      },
      "PAYMENT": {
        "SHORT": "Allocate payment",
        "LONG": "Allocate your payment",
      },
      "OVERVIEW": {
        "SHORT": "Pay",
        "LONG": "Overview",
      },
    },
  },
  "PAYMENT": {
    "USE_CARD": "Pay with card",
    "USE_SAVED_CARD": "Pay with saved card",
  },
  "PAYMENT_METHODS": {
    "APPLE_PAY": "pay",
  },
  "CUSTOMER_SERVICE": {
    "TEXT": "Customer service",
    "LINK": "/customer-service",
  },
  "SUCCESS": {
    "TITLE": "Order completed",
    "SUB_TITLE": "Order id %number",
    "BACK": "Back to the store",
    "VALID_UNTIL": "Valid until %date",
    "WHAT_NOW": "What's next?",
    "ORDER_OVERVIEW": "Order overview",
    "NOT_FOUND_TITLE": "No orders found.",
    "NOT_FOUND_SUBTITLE": "Contact customer service if this is incorrect.",
    "CONTACT": {
      "TITLE": "Got a question?",
      "CREATE_TICKET": "Send us a message",
      "INFO": "Do you have a question about a product or about your order? Do not hesitate to contact us.",
      "CALL_US": "Call us",
      "CALL_US_HOURS": "09.00-11.00, on weekdays",
      "CALL_US_PHONENUMBER": "+46 (0)31 700 83 86",
    },
    "STEPS": {
      "NORMAL": {
        "1": {
          "HEADING": "Confirmation",
          "TEXT": "An order confirmation will be sent to %email shortly.",
        },
        "2": {
          "HEADING": "Processing",
          "TEXT": "Your order will be dispatched as soon as all products are available for delivery. Normally, this happens within 24h.",
        },
        "3": {
          "HEADING": "Shipping",
          "TEXT": "When your items have been picked and packed the will be dispatched to the shipping agent. As soon as the parcel is registered, you will receive a shipping confirmation via email, containing a tracking link with which you'll be able to follow the parcel on its journey.",
        },
        "4": {
          "HEADING": "Delivery",
          "TEXT": "The package will be delivered to the nearest pick-up point and you will be notified via SMS to the mobile number you provided in the order. The package will be held at the pick-up point for 14 days.",
        },
      },
      "MIXED": {
        "1": {
          "HEADING": "Confirmation",
          "TEXT": "An order confirmation, as well as the voucher codes you've ordered, will be sent to %email shortly.",
        },
        "2": {
          "HEADING": "Processing",
          "TEXT": "Your order will be dispatched as soon as all products are available for delivery. Normally, this happens within 24h. Digital products will be sent directly to the provided email.",
        },
        "3": {
          "HEADING": "Shipping",
          "TEXT": "When your items have been picked and packed the will be dispatched to the shipping agent. As soon as the parcel is registered, you will receive a shipping confirmation via email, containing a tracking link with which you'll be able to follow the parcel on its journey.",
        },
        "4": {
          "HEADING": "Delivery",
          "TEXT": "The package will be delivered to the nearest pick-up point and you will be notified via SMS to the mobile number you provided in the order. The package will be held at the pick-up point for 14 days. Your digital order will be sent directly to your provided email.",
        },
      },
      "VIRTUAL": {
        "1": {
          "HEADING": "Confirmation",
          "TEXT": "An order confirmation will be sent to %email shortly.",
        },
        "2": {
          "HEADING": "Digital delivery",
          "TEXT": "Your order will be sent directly to %email. If you have not received an email, please double check your spam filter and junk mail before contacting customer service.",
        },
      },
    },
  },
  "SERIAL_CODE": {
    "FETCH_STATUS": {
      "LOADING": "Please wait while we fetch your voucher code",
      "ERROR": "An error occured. Your code is sent to your e-mail",
    },
  },
  "OLD_BROWSER": {
    "INFO": "Hi! You are using an outdated browser. Please. <a href=\"http://outdatedbrowser.com\" rel=\"noopener\" target=\"blank\">update your browser</a> to be able to use saseurobonusshop.com",
  },
  "CATEGORY": {
    "PRODUCTS_IN_CATEGORY_TITLE_SINGULAR": "Viewing 1 product in",
    "PRODUCTS_IN_CATEGORY_TITLE_PLURAL": "Viewing %itemsCount products in",
    "PRODUCTS_IN_CATEGORY_TITLE_EMPTY": "Viewing products in",
  },
  "PRODUCTLIST": {
    "EMPTY": "No products to show",
  },
  "POPULAR": {
    "POPULAR_PRODUCTS": "Popular products",
  },
  "404": {
    "TITLE": "Sorry, this page could not be found.",
    "HEADING": "— 404 —",
    "SUBHEADING": "Sorry, this page could not be found.",
  },
  "SERVER_ERROR": {
    "TITLE": "Server error",
    "HEADING": "Server error: 500",
    "SUBHEADING": "We apologise – please return in a while.",
    "TEXT": "<strong>Need to get in touch?</strong><br/>Contact us via our <a href=\"mailto:info@awardit.com\">info@awardit.com</a>.",
  },
  "SEARCH": {
    "TITLE_PREFIX": "Search: ",
    "PLACEHOLDER": "Search the EuroBonus assortment",
    "RESULTS": "Search results for \"%query\"",
    "TYPE": {
      "CATEGORY": "Category",
      "PRODUCT": "Product",
      "BRAND": "Brand",
    },
  },
  "HEADER": {
    "SHOPNAME": "EuroBonus Shop",
    "NAVIGATION": "Navigation",
    "SEARCH": "Search",
    "CATEGORIES": "Categories",
    "BRANDS": "Brands",
    "CART": "Checkout",
    "LOGOUT": "Log out",
    "LOGIN": "Log in",
    "DELIVER_TO": "Deliver to",
    "GO_TO_SAS": "Go to flysas.com",
    "MY_ACCOUNT": "My account",
    "GIFTCARDS": "Gift Cards & Vouchers",
  },
  "FOOTER": {
    "LINKS": {
      "TERMS": {
        "TEXT": "Terms",
        "LINK": "/terms",
      },
      "PRIVACY_POLICY": {
        "TEXT": "Privacy policy",
        "LINK": "/privacy-policy",
      },
    },
    "COPYRIGHT": "© %year Scandinavian Airlines System-Denmark-Norway-Sweden, org.nr 902001-7720, 195 87 Stockholm",
    "NEED_HELP": {
      "COOKIES": "Manage cookies",
    },
  },
  "BREADCRUMBS": {
    "HOME": "Home",
    "HOME_URL": "/",
    "SEARCH": "Search results for \"%query\"",
    "ADVENT_CALENDAR": "Advent Calendar",
  },
  "PRODUCT": {
    "ADD_TO_CART": "Add to cart",
    "OUT_OF_STOCK": "Out of stock",
    "OTHERS_ALSO_LIKED": "Other items you might be interested in",
    "OPTION_DROPDOWN_PLACEHOLDER": "Please select",
    "LOGIN_TO_PURCHASE": "Please log in, in order to purchase",
    "CHANGE_IMAGE": "Change image",
    "GO_TO_MANUFACTURER": "View all products from %brand",
    "ABOUT_BRAND": "About %brand",
    "COLOR": "Color:",
    "CHOOSE_A_COLOR": "Pick a color",
    "POINTS_ONLY": "Points only",
    "EARN": "Earn %points",
    "EARN_UP_TO": "Earn up to %points",
    "PAY_AT_LEAST_PTS": "You'll have to redeem at least %points points when purchasing this product. The remaining amount can be paid using card.",
    "PAY_ONLY_WITH_PTS": "This product can only be purchased with points.",
    "DESCRIPTION": "Product description",
    "EARNINGS": "Earnings from this purchase",
    "SHIPPING_TERMS": "Shipping terms",
    "ACCRUAL_TOOLTIP_TITLE": "Earn points",
    "ACCRUAL_TOOLTIP_DESCRIPTION": "You'll earn Eurobonus-points when you buy this product. Points are credited to your account 1 month after the date of purchase.",
    "REDEMPTION_DISABLED": "You can't buy anything using this account at the moment. Please Contact SAS Customer service",
    "EXPERIENCE_URL_BUTTON": "To the product",
    "EXTERNAL_EXPERIENCE": "SAS Experience",
    "EXTERNAL_EXPERIENCE_URL": "/experiences-by-eurobonus",
  },
  "STRIPE": {
    "PROCESSING": "Loading...",
    "CARD_NUMBER": "Card number",
    "MM/YY": "MM/YY",
    "CVC": "CVC",
  },
  "OTP": {
    "TITLE": "Verify ownership of your EuroBonus account",
    "INFO": "A verification code has been sent to the phone number registered on your EuroBonus profile.",
    "FIELD_LABEL": "Code",
    "SUBMIT": "Verify ownership",
    "REQUEST_NEW_CODE_TITLE": "Haven't received a code?",
    "REQUEST_NEW_CODE": "Request new code",
    "CUSTOMER_SERVICE": "Contact customer service",
  },
  "VALIDATION": {
    "REQUIRED": "*",
    "EMAIL": "Please enter a valid email address.",
    "errorInvalidEmail": "Please enter a valid email address.",
    "errorEmailExists": "This email is already in use by another account.",
    "PHONE": "Please enter a valid phone number.",
    "NUMERIC": "This is a numeric field.",
    "LENGTH_LT": "This field contains too many characters.",
    "POSTCODE": "Please enter a valid postal code.",
    "NO_SHIPPING_GB": "Not available due to Brexit.",
  },
  "STATUS_CODE": {
    /* Client */
    "UNKNOWN": "An unknown error occured. Please reload the page and try again.",
    "NETWORK_ERROR": "The network call was canceled. Please verify that you have an active internet connection and try again.",
    "PLACE_ORDER_CATCH": "An unknown error occured. Please try again later.",

    "errorUnknownProduct": "Unknown product. Please try again.",
    "errorNoPaymentMethod": "No payment method selected.",
    "errorProductQuantityNotAvailable": "The requested quantity is not currently available.",
    "errorProductNotInStock": "This product is currently out of stock.",
    "errorProductMaxQuantity": "We don't have the requested quantity in stock.",
    "errorProductNotChosenVariant": "Please select %attributeLabel in order to place this item in your cart.",
    "termsNotAccepted": "You must approve the terms and conditions to complete your order.",

    "errorNotLoggedIn": "You're not logged in. Please log in again.",
    "errorInvalidState": "An unknown error occured. Please try again.",
    "errorInvalidLogin": "Invalid login details.",
    "errorMissingCustomer": "The customer you are trying to login as does not exist.",
    "errorInsufficientSasPointsForPurchase": "You don't have the points to complete this purchase.",
    "errorBlacklistedEmail": "This email is already in use by another account. Please use another one.",
    "errorInvalidEmail": "The email address is not correct, please check it.",
    "errorEmailExists": "This email is already in use by another account. Please use another one.",
    "errorInvalidEmailOrPassword": "Invalid login details.",

    "Your card number is incomplete": "Please enter your card details.",

    "YOUR_CARD_HAS_INSUFFICIENT_FUNDS": "Insufficient funds on card.",
    "YOUR_CARDS_SECURITY_CODE_IS_INCORRECT": "The CVC code for this card is invalid.",
    "YOUR_CARD_WAS_DECLINED": "The card was declined.",
    "YOUR_CARD_HAS_EXPIRED": "The card has expired.",
    "AN_ERROR_OCCURRED_WHILE_PROCESSING_YOUR_CARD_TRY_AGAIN_IN_A_LITTLE_BIT": "An error occurred. Please try again later",
    "WE_ARE_UNABLE_TO_AUTHENTICATE_YOUR_PAYMENT_METHOD_PLEASE_CHOOSE_A_DIFFERENT_PAYMENT_METHOD_AND_TRY_AGAIN": "We could't authenticate your payment method. Please select an alternative payment method (if applicable) and try again.",
    "INTERNAL_SERVER_ERROR": "Internal server error.",

    "CODE_COPIED": "The voucher code has been copied to your clipboard",
    "CODE_NOT_COPIED": "The voucher code could not be copied",
    "WISHLIST_VARIANT": "You must select a variant in order to add this product to your wishlist.",
    "errorMaxAttemptsReached": "Too many calls. Please wait a few minutes and try again.",
    "errorPointRedemptionNotAllowed": "Your account is unable to redeem points at the moment.",
    "invalid_sso_token_or_otp": "Felaktig OTP. Vänligen försök igen",
    "invalid_sso_ticket": "Your session timed out. Please login again.",
    "errorNoTicket": "Your session timed out. Please login again.",
    "NEW_OTP_SMS_SENT": "A new code was sent",
    "ITEM_REMOVED_FROM_CART": "%name was removed from your cart since it is no longer in stock.",
    "NO_SHIPPING_GB": "Due to Brexit and the new rules, we are currently unable to deliver to the UK. We are working full time on this and hope for a solution soon.",
    "errorGeneralError": "Unfortunately, we couldn't send a verification code to you - We ask you to try again later or contact SAS customer service.",

    "ADVENT_CALENDAR": {
      "notModified": "This email address is already subscribed",
      "invalidEmail": "Invalid email address",
      "success": "You are now subscribed",
    },

    "TRANSFER_POINTS": {
      "errorNotFound": "We couldn't find a user with that EuroBonus number.",
      "errorTooManySuccess": "Too many search attempts. Please try again later.",
      "noSuccess": "An unknown error occured. Please reload the page and try again.",
      "errorCannotTransferTo": "It is not possible to transfer points to this recipient.",
      "errorPointRedemptionNotAllowed": "You are not currently in a qualification or grace-period where the points will count towards your bonus level.",
      "errorCannotBuyForSelf": "Your account does not meet the requirements to buy points for yourself.",
      "errorCannotBuyForOther": "Your account does not meet the requirements to buy or transfer points to someone else’s account.",
    },
  },
};
